#partner {
    .swiper-pagination {
        position: relative;
        margin-top: 3rem;
        .swiper-pagination-bullet {
            height: 10px;
            width: 10px;
            transition: .2s ease-in-out;
            &:hover {
                background-color: rgba(0,0,0,.75);
                opacity: 1;
            }
            &.swiper-pagination-bullet-active {
                background-color: rgba(0,0,0,.75);
            }
        }
    }
    .swiper-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .headline {
        @media screen and (min-width: 1200px) {
            width: 50%;
        }
    }
}