#about {
    h1, h2, h3, h4, h5, h6 {
        color: #ead8bb;
    }
    h2 {
        font-size: 7vh;
        line-height: 7vh;
        margin: 0;
        @media screen and (min-width: 768px) {
            font-size: 3rem;
            line-height: 2.7rem;
        }
        @media screen and (min-width: 1200px) {
            font-size: 3.6rem;
            line-height: 3.8rem;
        }
    }
    h6 {
        font-size: 3vh;
        line-height: 4vh;
        margin-top: 1rem;
        margin-bottom: .5rem;
        font-family: "Muli", sans-serif;
        text-transform: initial;
        @media screen and (min-width: 768px) {
            font-size: 4vh;
            line-height: 5vh;
        }
    }
    p {
        color: #ead8bb;
        @media screen and (min-width: 768px) {
            font-size: 1.8rem;
            line-height: 2rem;
        }
    }
}