.projects-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.5rem;
    .projects-item {
        margin: 0;
        position: relative;
        margin-bottom: 1rem;
        padding: 0 .5rem;
        &:last-child {
            margin-bottom: 0;
        }
        @media screen and (min-width: 580px) {
            width: 50%;
            flex: 0 0 50%;
            &:last-child {
                margin-bottom: 1rem;
            }
        }
        @media screen and (min-width: 992px) {
            width: 33.3333%;
            flex: 0 0 33.3333%;
            margin-bottom: initial;
            margin-top: 1rem;
            &:nth-child(-n+3) {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: initial;
            }
        }
        figcaption {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 2;
            padding: 2rem;
            color: #ead8bb;
            width: calc(100% - 1rem);
            h2 {
                margin: 0;
            }
            p {
                margin-bottom: 0;
                margin-top: 1rem;
                font-size: 1.2rem;
            }
        }
    }
}