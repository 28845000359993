@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Black.woff2') format('woff2'),
        url('../fonts/Montserrat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Regular.woff2') format('woff2'),
        url('../fonts/Muli-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Bold.woff2') format('woff2'),
        url('../fonts/Muli-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

body {
    font-size: 1rem;
    font-family: "Muli", sans-serif;
    font-weight: normal;
    line-height: 1.5rem;
    color: #272934;
    @media screen and (min-width: 768px) {
        font-size: 1.19rem;
    }
}

* {
    box-sizing: border-box;
}

input {
    width: 100%;
    max-width: 100%;
    position: relative;
    border: 2px solid #ead8bb;
    background-color: transparent;
    color: #ead8bb;
    padding: .5rem 1.5rem;
    &[type="checkbox"] {
        width: auto;
    }
    &::placeholder {
        color: #ead8bb;
        opacity: 1;
    }
      
    &:-ms-input-placeholder {
        color: #ead8bb;
    }
    
    &::-ms-input-placeholder {
        color: #ead8bb;
    }
}

address {
    font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.App {
    max-width: 100%;
    position: relative;
    overflow: hidden;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.btn {
    display: inline-block;
    width: auto;
    cursor: pointer;
    &.secondary {
        background-color: #272934;
        color: #ead8bb;
        font-family: "Montserrat", sans-serif;
        font-weight: 900;
        padding: .5rem 2rem;
        text-decoration: none;
        font-size: 1rem;
        transition: .2s ease-in-out;
        border: 3px solid transparent;
        &:hover {
            background-color: transparent;
            color: #272934;
            border: 3px solid #272934;
        }
        @media screen and (min-width: 768px) {
            padding: .5rem 2.5rem;
        }
        @media screen and (min-width: 992px) {
            padding: .75rem 3rem
        }
        @media screen and (min-width: 1200px) {
            padding: .75rem 3.4rem;
        }
    }
    &.standard {
        background-color: #ead8bb;
        color: #272934;
        font-family: "Montserrat", sans-serif;
        font-weight: 900;
        padding: .5rem 2rem;
        text-decoration: none;
        font-size: 1rem;
        transition: .2s ease-in-out;
        border: 3px solid transparent;
        &:hover {
            background-color: #272934;
            color: #ead8bb;
            border: 3px solid #ead8bb;
        }
        @media screen and (min-width: 768px) {
            padding: .5rem 2.5rem;
        }
        @media screen and (min-width: 992px) {
            font-size: 1.25rem;
            padding: .75rem 3rem
        }
        @media screen and (min-width: 1200px) {
            padding: .75rem 3.4rem;
        }
    }
}

.headline {
    margin-bottom: 2rem;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
        width: 60%;
        margin-bottom: 4rem;
    }
    @media screen and (min-width: 992px) {
        width: 50%;
    }
    @media screen and (min-width: 1200px) {
        width: 40%;
        margin-bottom: 77px;
    }
    &.center {
        text-align: center;
    }
    h1, h2, h3, h4, h5, h6 {
        text-transform: initial;
        line-height: 2rem;
        color: #272934;
    }
}

.line {
    padding: 70px 0;
    @media screen and (max-width: 1200px) {
        padding: 140px 0;
    }
    @media screen and (max-width: 768px) {
        padding: 110px 0;
    }
    @media screen and (max-width: 480px) {
        padding: 2rem 0;
    }
}

.menu {
    margin: 0;
    padding: 0;
    list-style: none;
    &.horizontal {
        display: flex;
    }
}

.img-responsive {
    display: block;
    position: relative;
    width: 100%;
    max-width: 100%;
}

.half {
    width: 100%;
    flex: 0 0 100%;
    padding: 0 1rem;
    @media screen and (min-width: 768px) {
        width: 50%;
        flex: 0 0 50%;
    }
}

.container, .container-fluid {
    padding: 0 1rem;
    margin: 0 auto;
}

.container {
    @media screen and (min-width: 1200px) {
        max-width: 1170px;
        width: 100%;
    }
}

.container-fluid {
    @media screen and (min-width: 2000px) {
        max-width: 2000px;
        width: 100%;
    }
}

.grid {
    padding: 0 1rem;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    &.justify-between {
        justify-content: space-between;
    }
    &.align-center {
        align-items: center;
    }
    @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
    }
}

#content {
    padding-top: 62px;
    @media screen and (min-width: 767px) {
        padding-top: 173px;
    }
}