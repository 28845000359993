#contact {
    background-color: #ead8bb;
    position: relative;
    padding-bottom: 2rem;
    @media screen and (min-width: 480px) {
        padding-bottom: 0;
    }
    .row {
        &.align-center {
            align-items: initial;
        }
    }
    .contact-actions {
        h2 {
            margin: 0;
        }
        @media screen and (min-width: 580px) {
            padding: 1rem;
        }
        @media screen and (min-width: 992px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 3%;
            h2 {
                font-size: 2.8rem;
                line-height: 2.8rem;
            }
        }
    }
    .contact-image, .contact-actions {
        @media screen and (min-width: 580px) {
            flex: 0 0 50%;
        }
    }
    .contact-image {
        padding: 0;
        margin-bottom: 1rem;
        position: relative;
        @media screen and (min-width: 580px) {
            margin-bottom: 0;
        }
        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
        .caption {
            position: absolute;
            padding: 0 1rem;
            bottom: 1rem;
            color: #ead8bb;
            @media screen and (min-width: 768px) {
                bottom: 0;
                padding: 7% 12%;
            }
            p {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
    .contact-person {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 2rem -1rem 0 -1rem;
        max-width: 550px;
        .contact-info, .contact-profileImage {
            padding: 0 1rem;
            flex: 0 0 100%;
            width: 100%;
            @media screen and (min-width: 992px) {
                flex: 1;
                width: auto;
            }
        }
        .contact-profileImage {
            text-align: center;
            img {
                border-radius: 100%;
                max-width: 100px;
                @media screen and (min-width: 768px) {
                    max-width: 140px;
                }
            }
        }
        .contact-info {
            text-align: center;
            margin-top: 1rem;
            @media screen and (min-width: 992px) {
                text-align: left;
                flex: 5;
            }
            @media screen and (min-width: 480px) {
                margin-top: 0;
            }
            h3 {
                margin: 0;
                text-transform: initial;
                font-size: 4vh;
            }
            p {
                margin: 0;
                font-size: 3vh;
            }
            .contact-telephone {
                margin-top: .5rem;
                a {
                    color: #000000;
                    font-family: "Montserrat", sans-serif;
                    font-weight: 900;
                    text-decoration: none;
                    font-size: 4vh;
                }
            }
            @media screen and (min-width: 580px) {
                h3 {
                    font-size: 1.5rem;
                }
                p {
                    font-size: 1rem;
                }
                .contact-telephone {
                    a {
                        font-size: 1.5rem;
                    }
                }
            }
            @media screen and (min-width: 768px) {
                h3 {
                    font-size: 2rem;
                    margin-bottom: .25rem;
                }
                p {
                    font-size: 1.25rem;
                    margin-bottom: 1rem;
                }
                .contact-telephone {
                    a {
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}