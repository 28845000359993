.service-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.5rem;
    .service-item {
        position: relative;
        padding: 0 .5rem;
        margin: 0 0 1rem 0;
        width: 33.3333%;
        flex: 0 0 33.3333%;
        margin-bottom: 1rem;
        &:nth-child(-n+2) {
            margin-top: initial;
        }
        &:nth-child(-n+3) {
            margin-top: 0;
        }
        @media screen and (max-width: 992px) {
            width: 50%;
            flex: 0 0 50%;
            margin-bottom: 0;
            margin-top: 1rem;
            &:nth-child(-n+2) {
                margin-top: 0;
            }
            &:nth-child(3) {
                margin-top: 1rem;
            }
        }
        @media screen and (max-width: 520px) {
            width: 100%;
            flex: 0 0 100%;
            &:nth-child(2) {
                margin-top: 1rem;
            }
        }
        figcaption {
            position: absolute;
            bottom: 0;
            padding: 2rem;
            text-align: center;
            width: 100%;
            max-width: calc( 100% - 1rem );
            color: #ead8bb;
            @media screen and (max-width: 560px) {
                p {
                    font-size: .875rem;
                }
            }
        }
    }
}