#contact-form {
    background-color: #272934;
    color: #ead8bb;
    @media screen and (min-width: 992px) {
        padding: 150px 0;
    }
    a {
        color: #ead8bb;
        &:hover {
            text-decoration: none;
        }
    }
    h2 {
        margin: 0;
        font-size: 7vh;
        line-height: 7vh;
        @media screen and (min-width: 768px) {
            font-size: 2.2rem;
            line-height: 2.2rem;
        }
        @media screen and (min-width: 1200px) {
            font-size: 3rem;
            line-height: 3rem;
        }
    }
}

.contact-form {
    p {
        margin: 0 0 1rem 0;
    }
    .privacy {
        display: flex;
        justify-content: flex-start;
        p {
            margin: -.5rem 0 0 0;
            padding-left: 1rem;
        }
    }
}