.facts-wrapper {
    display: flex;
    margin-top: 2rem;
    flex-wrap: wrap;
    margin: 0 -2rem;
    .fact-item {
        text-align: center;
        margin-bottom: 2rem;
        padding: 0 2rem;
        @media screen and (min-width: 580px) {
            flex: 0 0 50%;
            width: 50%;
            margin-bottom: 0;
            margin-top: 2rem;
            &:nth-child(-n+2) {
                margin-top: 0;
            }
        }
        @media screen and (min-width: 992px) {
            flex: 0 0 33.3333%;
            width: 33.3333%;
            margin-top: 4rem;
            &:nth-child(-n+2) {
                margin-top: initial;
            }
            &:nth-child(-n+3) {
                margin-top: 0;
            }
        }
        .fact-value {
            font-family: "Montserrat", sans-serif;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 10vh;
            color: #272934;
            @media screen and (min-width: 768px) {
                font-size: 3rem;
            }
            @media screen and (min-width: 992px) {
                font-size: 4rem;
            }
        }
        .fact-title {
            h2 {
                color: #ead8bb;
            }
        }
    }
}