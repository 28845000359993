.content-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    position: relative;
    overflow: hidden;
    color: #272934;
    &:nth-child(2n) {
        flex-direction: row;
    }
    .image-content {
        height: auto;
        object-fit: cover;
        position: relative;
        overflow: hidden;
        @media screen and (min-width: 580px) {
            max-height: initial;
        }
        img {
            display: block;
            position: relative;
            height: 100%;
            max-width: 100%;
        }
    }
    .text-content {
        background-color: #ead8bb;
        color: #272934;
        .text-inner {
            padding: 1rem 2rem;
            @media screen and (min-width: 768px) {
                h2 {
                    font-size: 2.2rem;
                    line-height: 2.2rem;
                }
                p {
                    &.subline {
                        margin-bottom: -1rem;
                    }
                }
            }
            @media screen and (min-width: 992px) {
                padding: 1rem 4rem;
            }
        }
        @media screen and (min-width: 768px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    .image-content, .text-content {
        width: 100%;
        max-width: 100%;
    }
    @media screen and (min-width: 630px) {
        .image-content, .text-content {
            flex: 1;
        }
    }
}