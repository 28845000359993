#showcase {
    background-color: #272934;
    color: #ead8bb;
    text-align: center;
    h2 {
        @media screen and (min-width: 992px) {
            font-size: 2.8rem;
            line-height: 2.8rem;
            max-width: 75%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    p {
        @media screen and (min-width: 1200px) {
            max-width: 75%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .logo-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1rem;
        flex-direction: column;
        justify-content: center;
        @media screen and (min-width: 320px) {
            flex-direction: row;
        }
        @media screen and (min-width: 767px) {
            margin: 0 -5%;
        }
        @media screen and (min-width: 992px) {
            margin-top: 4rem;
        }
        .logo-item {
            padding: 0 1rem;
            margin-bottom: 1rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @media screen and (min-width: 464px) {
                margin-bottom: 0;
            }
            @media screen and (min-width: 767px) {
                padding: 0 5%;
            }
            img {
                max-width: 400px;
            }
        }
    }
}