.testimonial-wrapper {
    display: flex;
    flex-wrap: wrap;
    .testimonial-image, .testimonial-review {
        width: 100%;
        max-width: 100%;
    }
    .testimonial-review {
        background-color: #ead8bb;
        padding: 2rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        h2 {
            margin: 0;
            font-size: 3rem;
            line-height: 3rem;
            @media screen and (max-width: 1200px) {
                font-size: 3rem;
                line-height: 3rem;
            }
            @media screen and (max-width: 992px) {
                font-size: 1.75rem;
                line-height: 2.25rem;
            }
            @media screen and (max-width: 768px) {
                font-size: 1.5rem;
                line-height: 1.75rem;
            }
            @media screen and (max-width: 480px) {
                font-size: 1.5rem;
                line-height: 1.75rem;
            }
        }
    }
    .testimonial-image {
        img {
            object-fit: cover;
            height: 100%;
        }
    }
    @media screen and (min-width: 992px) {
        .testimonial-review {
            padding: 4rem;
        }
    }
    @media screen and (min-width: 768px) {
        .testimonial-image, .testimonial-review {
            flex: 0 0 50%;
            width: 50%;
        }
    }
}