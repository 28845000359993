#footer {
    background-color: #000000;
    color: #ffffff;
    padding: 1rem 0;
    @media screen and (min-width: 992px) {
        padding: 4rem 10%;
    }
    a {
        color: #ffffff;
        text-decoration: none;
        &:hover {
            color: #ead8bb;
        }
    }
    .menu {
        li {
            line-height: 2.7rem;
        }
    }
    .row {
        align-items: center;
    }
    .footer-widget {
        padding: 0 1rem;
        flex: 0 0 100%;
        &.social {
            margin: 2rem 0;
            a {
                padding: 0 .5rem;
                &:hover {
                    svg {
                        path {
                            fill: #ead8bb;
                        }
                    }
                }
            }
        }
        svg {
            max-width: 50px;
            width: 50px;
            path {
                fill: #ffffff;
            }
        }
        @media screen and (min-width: 768px) {
            flex: initial;
        }
    }
}