#intro {
    position: relative;
    height: 100vh;
    color: #ead8bb;
    @media screen and (min-width: 768px) {
        min-height: 660px;
    }
    h1, h2 {
        font-size: 3rem;
        line-height: 2.7rem;
        font-weight: 900;
        margin: 0;
        @media screen and (max-width: 1200px) {
            font-size: 3.6rem;
            line-height: 3.8rem;
        }
        @media screen and (max-width: 768px) {
            font-size: 3rem;
            line-height: 2.7rem;
        }
        @media screen and (max-width: 480px) {
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }
    .container {
        height: 100%;
        .caption-wrapper {
            height: 100%;
            position: relative;
            .caption-inner {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                @media screen and (min-width: 768px) {
                    width: 50%;
                }
                p {
                    @media screen and (min-width: 1200px) {
                        font-size: 1.8rem;
                        line-height: 2rem;
                    }
                }
            }
        }
    }
}