.team-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.5rem;
    .team-item {
        margin: 0 0 1rem 0;
        position: relative;
        color: #ffffff;
        padding: 0 .5rem;
        @media screen and (min-width: 480px) {
            flex: 0 0 50%;
            width: 50%;
        }
        @media screen and (min-width: 992px) {
            flex: 0 0 33.3333%;
            width: 33.3333%;
            margin-bottom: 0;
            margin-top: .5rem;
            &:nth-child(-n+3) {
                margin-top: 0;
            }
        }
        figcaption {
            position: absolute;
            bottom: 0;
            padding: 1rem;
            max-width: calc(100% - 1rem);
            @media screen and (min-width: 580px) {
                padding: 2rem;
                max-width: calc(100% - 1rem);
            }
            @media screen and (min-width: 992px) {
                padding: 3rem;
                max-width: calc(100% - 3rem);
            }
            h2 {
                font-family: "Montserrat", sans-serif;
                font-weight: 900;
                text-transform: uppercase;
                margin: 0;
                line-height: 2rem;
                @media screen and (min-width: 768px) {
                    font-size: 2rem;
                }
            }
            p {
                &.position {
                    margin-top: .25rem;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}